import gql from 'graphql-tag';

export const MarriageFragmentInclude = `
    ID
    Type
    Gender
    FirstName
    MiddleName
    Surname
    CurrentSurname
    AgeMarried
    Country
    State
    Suburb
    LastEdited
    Created
`;

export default gql`
    fragment Marriages on Funeral {
        Marriages {
            ${MarriageFragmentInclude}
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementMarriages on Prearrangement {
        Marriages {
            ${MarriageFragmentInclude}
        }
    }
`;
