import gql from 'graphql-tag';

export const ChildrenFragmentInclude = `
    ID
    LastEdited
    Created
    GivenName
    FamilyName
    FamilyNameAtBirth
    OtherGivenNames
    DateOfBirth
    Age
    AgeExtra
    Gender
    LifeStatus
`;

export default gql`
    fragment ChildrenDetails on Funeral {
        ChildrenDetails {
            ${ChildrenFragmentInclude}
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementChildrenDetails on Prearrangement {
        ChildrenDetails {
            ${ChildrenFragmentInclude}
        }
    }
`;
