import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import moment from 'moment';
import { getValidationDecorations } from '../../util/validation';
import { niceDateFromString, niceTimeFromString } from '../../util/date';
import { onChangeWrapper } from '../../util/functions';

/**
 * Extension on the TextField from material ui adding:
 * - default theme/style
 * - optional wiring to provided form state
 */

class FormTextField extends Component {
    render() {
        let {
            classes,
            name,
            id,
            form,
            value,
            rows,
            rowsMax,
            title,
            InputProps = {},
            InputLabelProps,
            fullWidth,
            label,
            type,
            notFuture,
            validationResult,
            error,
            titleCase,
            onChange,
            min,
            max,
            step,
            list,
            helperText,
            ...other
        } = this.props;

        if (this.props.readOnly || (InputProps && InputProps.readOnly)) {
            let newVal = form ? form.getField(name) : value;

            if (!!type && (type === 'date' || type === 'time')) {
                // for nicer display
                if (type === 'date') newVal = niceDateFromString(newVal);
                else if (type === 'time') newVal = niceTimeFromString(newVal);
            }

            return (
                <div className={classes.readOnlyBucket}>
                    <div className={cx(classes.readOnlyLabel, classes.label)}>{label}</div>
                    <div className={classes.readOnlyItem}>
                        {InputProps.startAdornment}
                        <div className={classes.readOnly}>
                            {newVal ? ('' + newVal).replace(/(\r\n|\r)/g, '\n') : ''}
                        </div>
                        {InputProps.endAdornment}
                    </div>
                </div>
            );
        }

        if (notFuture) {
            max = moment().format('YYYY-MM-DD');
        }

        const decorations = getValidationDecorations(this.props, !other.multiline ? classes.root : classes.textarea);

        let formValue = form ? form.getField(name) : value;
        if (type !== 'number') {
            if (!formValue) formValue = '';
        } else {
            if (!formValue) formValue = 0;
        }

        if (!!type && (type === 'date' || type === 'time')) {
            InputLabelProps = { shrink: true };
            if (type === 'time') formValue = ('' + formValue).replace(/(:\d\d):00/, '$1'); // trim seconds, for nicer display
        }

        return (
            <>
                <TextField
                    FormHelperTextProps={{
                        error: !!decorations.inError,
                        classes: { error: decorations.validationLabel }
                    }}
                    helperText={decorations.errorMsg || helperText}
                    title={title}
                    id={id || (name ? `text-${name}` : undefined)}
                    name={name}
                    rows={!!other.multiline ? (!!rows ? rows : 4) : null}
                    rowsMax={!!other.multiline ? (!!rowsMax ? rowsMax : 9) : null}
                    value={formValue}
                    onChange={e => this.onChange(e)}
                    onBlur={e => this.onBlur(e)}
                    fullWidth={fullWidth}
                    label={label}
                    type={type}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            root: decorations.rootClass,
                            focused: decorations.focusedClass,
                            disabled: classes.disabledClass
                        },
                        ...InputProps
                    }}
                    InputLabelProps={{
                        className: cx(classes.label),
                        classes: {
                            shrink: decorations.labelShrinkClass
                        },
                        ...InputLabelProps
                    }}
                    // note: inputProps is different to InputProps. but the linter picks them up as duplicate.
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                        min,
                        max,
                        step,
                        list: list && list.length ? `${name}-list` : undefined
                    }}
                    {...other}
                />
                {list && list.length && (
                    <datalist id={`${name}-list`}>
                        {list.map((obj, idx) => (
                            <option key={idx} value={obj.value}>
                                {obj.label}
                            </option>
                        ))}
                    </datalist>
                )}
            </>
        );
    }

    onChange(e) {
        const { type, titleCase } = this.props;

        let value = e.target.value;
        if (titleCase) {
            // note: this causes input cursor to jump to end. TODO: prevent cursor jump.
            e.target.value = value.replace(/((\s|^)[a-z])/g, l => l.toUpperCase());
        }

        onChangeWrapper(e, this, false, 'number' === type);
    }

    onBlur(e) {
        const { form, name, onBlur, type } = this.props;
        let {
            target: { value }
        } = e;
        if (!form) return;
        value = 'number' === type ? Number(value) : (value || '').trim();
        form.setField({ [name]: value || null });
        if (onBlur) onBlur(e);

        if (form && !form.getValidation) {
            console.log(name + ' form does not contain validation function!');
            return;
        }

        if (form.getValidation(name, true).shouldUpdate) this.forceUpdate();
    }
}

FormTextField.defaultProps = {
    fullWidth: true
};

const styles = ({ spacing, palette, transitions, validationLabel }) => ({
    root: {
        backgroundColor: palette.common.white,
        borderRadius: 3,
        border: `1px solid ${palette.grey.A100}`,
        padding: '2px 8px',
        transition: transitions.create(['border-color', 'box-shadow']),
        minWidth: 54, // 2 digits
        'label + &': {
            marginTop: spacing.unit * 2
        },
        '& > input': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '6px 0',
            height: 21
        }
    },
    textarea: {
        padding: '1rem',
        backgroundColor: palette.common.white,
        borderRadius: 3,
        border: `1px solid ${palette.grey.A100}`,
        transition: transitions.create(['border-color', 'box-shadow']),
        'label + &': {
            marginTop: spacing.unit * 2
        }
    },
    focused: {
        borderColor: palette.primary.light,
        boxShadow: `0 0 0 0.1rem rgba(65, 59, 190,.25)`
    },
    label: {
        zIndex: 1,
        pointerEvents: 'none',
        textTransform: 'capitalize',
        top: 2,
        left: 8,
        color: palette.text.hint,
        transition: transitions.create(['transform', 'color', 'padding', 'left', 'background-color', 'border-radius'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        })
    },
    labelShrink: {
        top: 0,
        left: 0,
        padding: '2px 8px',
        backgroundColor: palette.action.active,
        color: palette.common.white + ' !important',
        borderRadius: 4,
        borderBottomLeftRadius: 0,
        zIndex: 1,
        cursor: 'pointer',
        pointerEvents: 'auto'
    },
    disabledClass: { background: '#efefef', color: palette.text.primary },
    readOnlyLabel: {
        color: '#000',
        fontWeight: '500',
        fontSize: '0.95em',
        display: 'inline-block'
    },
    readOnlyItem: {
        display: 'flex',
        '& > div': {
            minHeight: 34
        }
    },
    readOnly: {
        whiteSpace: 'pre-wrap',
        padding: '5px 5px 5px 0',
        fontSize: '0.95em',
        fontStyle: 'italic'
    },
    validationErrorBorder: {
        border: `1px solid ${palette.validation.error} !important`
    },
    validationErrorLabel: {
        color: `${palette.validation.error} !important`,
        border: `1px solid ${palette.validation.error} !important`
    },
    validationSuggestedBorder: {
        border: `1px solid ${palette.validation.suggested} !important`
    },
    validationSuggestedLabel: {
        color: `${palette.validation.suggested} !important`,
        border: `1px solid ${palette.validation.suggested} !important`
    },
    validationOptionalBorder: {
        border: `1px solid ${palette.validation.optional} !important`
    },
    validationOptionalLabel: {
        color: `${palette.validation.optional} !important`,
        border: `1px solid ${palette.validation.optional} !important`
    },
    validationLabel: { ...validationLabel }
});

export default withStyles(styles)(FormTextField);
