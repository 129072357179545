import transform from 'lodash.transform';
import isObject from 'lodash.isobject';
import isEqual from 'lodash.isequal';
import { gqlArr as gql } from './graphql';
import { isRelatedObjectDefined } from './bookable';
import { joinDefined, stringIsNullOrEmpty } from './strings';
import { isNullOrUndefined } from './objects';

/**
 * Returns a new function which when invoked will execute all of the given functions
 * and propagating the same parameters into each function.
 */
export function applyAll(...functions) {
    return (...params) => {
        functions.forEach(func => typeof func === 'function' && func(...params));
    };
}

/**
 *
 * @param e the event fired
 * @param context the form object, eg. TextField
 * @param isTickbox for checkboxes
 * @param isNumber for numeric values, otherwise you get a string
 */
export const onChangeWrapper = (e, context, isTickbox = false, isNumber = false) => {
    /*
     * onChanging: function that will execute BEFORE the value is applied. If e.preventDefault() is called, this function will terminate
     * onChange: function that will execute AFTER the value is applied
     * valueField: get a nested result from the target
     * emptyValue: replace empty string with null
     */
    const { onChanging, onChange, name, form, valueField, emptyValue = null } = context.props;

    if (onChanging) onChanging(e);

    if (e.defaultPrevented) return;
    const valToCheck = !!isTickbox ? e.target.checked : e.target.value;
    let value = !stringIsNullOrEmpty(valueField)
        ? !isNullOrUndefined(valToCheck)
            ? valToCheck[valueField]
            : null
        : valToCheck;

    if (isNullOrUndefined(value)) value = emptyValue;

    if (isNumber) value = parseFloat(value);

    if (!isNullOrUndefined(form)) {
        if (!form.getValidation) throw new Error(name + ' form does not contain validation function!');

        if (!stringIsNullOrEmpty(name)) form.setField({ [name]: value });

        if (form && form.getValidation(name, true).shouldUpdate) context.forceUpdate();
    } else {
        context.setState({ value });
    }

    if (onChange) onChange(e, value);
};

/**
 * Super loose comparisons...
 * If the first value is undefined, return false.
 * If the first value is pretty much the same as the second value, return false.
 * '', null and undefined are equal.
 * 1234 and '1234' are equal.
 * [1,2] and '1,2' are equal.
 * 0 and false and '' are equal, 1 and true are equal.
 * Ignores case and trims whitespace
 */
export function isDefinedAndDifferent(firstValue, secondValue) {
    if (undefined === firstValue) return false;
    if (firstValue === secondValue) return false;
    // eslint-disable-next-line eqeqeq
    if (firstValue == secondValue) return false;
    if (new RegExp(`^${(firstValue + '').trim()}$`, 'i').test((secondValue + '').trim())) return false;
    return !(
        (isNullOrUndefined(firstValue) || '' === firstValue || !firstValue) &&
        (isNullOrUndefined(secondValue) || '' === secondValue)
    );
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @param  {boolean} compareArrays Whether to compare individual indicies of arrays. If false will just return the whole array if arrays differ
 * @return {Object}        Return a new object who represent the diff
 */
export function diff(object, base, compareArrays) {
    return changes(object, base, compareArrays);
}

function changes(object, base, compareArrays) {
    return transform(object, (result, value, key) => {
        if (!isEqual(value, base[key])) {
            result[key] =
                isObject(value) && isObject(base[key]) && (compareArrays || !Array.isArray(base[key]))
                    ? changes(value, base[key], compareArrays)
                    : value;
        }
    });
}

/**
 * This fixes values coming from the backend which should
 * be boolean true/false but are set as '1'/'0'
 */
export function fixBoolean(data, key) {
    if (data[key] === '0') data[key] = null;
    if (data[key] === '1') data[key] = true;
}

/**
 * Builds a single fragment combining the given list of fragments.
 * The combined fragment selects all necessary data for a particular funeral form tab.
 */
export function buildTabDataFragment(fragments, on) {
    const name = convertToHash(
        joinDefined(
            [
                on,
                joinDefined(
                    fragments.map(f => f.definitions[0].name.value),
                    ''
                )
            ],
            ''
        )
    );
    return gql`
        fragment TabData${name} on ${on} {
            ID
            LegacyKey
            ${fragments.map(f => `...${f.definitions[0].name.value}`).join('\n')}
        }
        ${fragments}
    `;
}

/**
 * Flattens edges and nodes in a connection data structure.
 * i.e. data => Children => edges => node becomes data => Children
 */
export function flattenConnection(data, propertyName) {
    if (!data || !data[propertyName] || data[propertyName].edges === undefined) return;
    data[propertyName] = data[propertyName].edges.map(e => e.node);
}

/**
 * Flattens staff allocations
 */
export function flattenStaffAllocations(data) {
    if (!data || !data.StaffAllocations) return;

    flattenConnection(data, 'StaffAllocations');
    for (let x = 0; x < data.StaffAllocations.length; x++) {
        let staffAllocation = data.StaffAllocations[x];
        const dateTimeFrom = staffAllocation.Start ? staffAllocation.Start.split(' ') : ['', ''];
        const dateTimeTo = staffAllocation.End ? staffAllocation.End.split(' ') : ['', ''];

        staffAllocation.DateFrom = dateTimeFrom[0];
        staffAllocation.DateTo = dateTimeTo[0];
        staffAllocation.TimeFrom = dateTimeFrom[1];
        staffAllocation.TimeTo = dateTimeTo[1];
    }
}

export function flattenCateringStaffAllocations(data) {
    if (!data || !data.CateringStaffAllocations) return;

    flattenConnection(data, 'CateringStaffAllocations');
    for (let x = 0; x < data.CateringStaffAllocations.length; x++) {
        let cateringStaffAllocation = data.CateringStaffAllocations[x];
        const dateTimeFrom = cateringStaffAllocation.Start ? cateringStaffAllocation.Start.split(' ') : ['', ''];
        const dateTimeTo = cateringStaffAllocation.End ? cateringStaffAllocation.End.split(' ') : ['', ''];

        //cateringStaffAllocation.Name = data
        cateringStaffAllocation.DateFrom = dateTimeFrom[0];
        cateringStaffAllocation.DateTo = dateTimeTo[0];
        cateringStaffAllocation.TimeFrom = dateTimeFrom[1];
        cateringStaffAllocation.TimeTo = dateTimeTo[1];
        if (isRelatedObjectDefined(cateringStaffAllocation.CateringStaff)) {
            cateringStaffAllocation.Name = cateringStaffAllocation.CateringStaff.Name;
            cateringStaffAllocation.CateringStaffID = cateringStaffAllocation.CateringStaff.ID;
        } else {
            cateringStaffAllocation.Name = '(Unknown)';
            cateringStaffAllocation.CateringStaffID = 0;
        }
    }
}

export function convertToHash(s) {
    let hash = 0,
        i,
        l,
        char;
    if (s.length === 0) return hash;
    for (i = 0, l = s.length; i < l; i++) {
        char = s.charCodeAt(i);
        hash = (hash << 7) - hash + char;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
}
