import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles/index';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

class AudioSampler extends React.Component {
    state = {
        isPlaying: false,
        isValid: true
    };
    audio = new Audio();
    componentDidMount = () => {
        this.audio.onerror = e => {
            this.setState({ isValid: false });
        };
        if (this.props.filename && 1 === 2) {
            // TODO: need to adjust all the mp3 file references... play sample for now.
            this.audio.src = this.props.filename;
            // } else if (this.props.filename) {
            //     this.audio.src = 'https://archive.org/download/testmp3testfile/mpthreetest.mp3';
        } else {
            this.setState({ isValid: false });
        }
    };

    togglePlay = e => {
        if (this.state.isValid) {
            if (this.state.isPlaying) {
                this.audio.pause();
                this.setState({ isPlaying: false });
            } else {
                this.audio.currentTime = 0;
                this.audio.play();
                this.setState({ isPlaying: true });
            }
        }
    };

    render() {
        const { classes, artist, title } = this.props;
        return (
            <Fragment>
                {this.state.isValid ? (
                    <IconButton onClick={this.togglePlay}>
                        {this.state.isPlaying ? <Icon>pause_circle_outline</Icon> : <Icon>play_circle_outline</Icon>}
                    </IconButton>
                ) : null}
                <div className={classes.infoBar}>
                    <span className={classes.infoText}>&ldquo;{title}&rdquo;</span>
                    <small className={classes.infoText}>By {artist || 'unknown'}</small>
                </div>
            </Fragment>
        );
    }
}

const styles = ({ palette, typography, sizes }) => ({
    infoBar: { display: 'flex', flexDirection: 'column', width: '100%', fontSize: '1rem', padding: '6px 0' },
    infoText: {}
});

export default withRouter(withStyles(styles)(AudioSampler));
