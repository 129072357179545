import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'react-apollo';

const LinearProgressIndicator = ({ classes, progress, theme }) => {
    const indicatorStyle = {
        marginLeft: `${progress}%`
    };
    if (progress < 50) indicatorStyle.background = theme.palette.validation.error;
    else if (progress < 100) indicatorStyle.background = theme.palette.validation.suggested;

    return (
        <div>
            <span style={{ width: '100%' }}>
                <span className={classes.indicator} style={indicatorStyle}>
                    <div />
                    <span>{Math.round(progress)}%</span>
                </span>
                <LinearProgress className={classes.progressBar} variant="determinate" value={progress} />
            </span>
        </div>
    );
};

const styles = ({ palette }) => ({
    indicator: {
        background: palette.validation.good,
        borderRadius: '5px',
        color: '#FFFFFF',
        padding: '3px 0',
        fontSize: '12px',
        display: 'inline-block',
        lineHeight: 'normal',
        transform: 'translate(-20px)',
        width: '40px',
        marginBottom: '10px',
        '& > span': {
            width: '100%',
            display: 'inline-block',
            textAlign: 'center'
        },
        '& > div': {
            background: 'inherit',
            display: 'inline-block',
            width: 10,
            height: 10,
            position: 'absolute',
            transform: 'rotate(45deg)',
            bottom: -4,
            zIndex: -1,
            left: '38%'
        }
    },

    progressBar: {
        height: '18px',
        '& > :first-child': {
            height: '18px'
        }
    }
});

export default compose(withTheme(), withStyles(styles))(LinearProgressIndicator);
