import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import TextField from '../../../component/form/TextField';
import PrimaryButton, { AltButton, OutlineButton } from '../../../component/form/PrimaryButton';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import ColumnLayout from '../../../component/ColumnLayout';
import Table, { TableCell, TableHeaderCell, TableHeaderRow, TableRow } from '../../../component/form/Table';
import { CUSTOMER_SATISFACTION_OPTIONS } from './BillingConstants';
import Select from '../../../component/form/Select';
import Checkbox from '../../../component/form/Checkbox';
import Label from '../../../component/form/Label';
import BillTo, { BILL_TO_MAPPING } from './BillTo';
import InvoiceModal from './invoiceModal/InvoiceModal';
import LinkButton from '../../../component/form/LinkButton';
import { withRouter } from 'react-router';
import { compose, Query } from 'react-apollo';
import { calculateTotals, INVOICE_STATUS_OPTIONS } from './invoiceModal/InvoiceModalConstants';
import DocumentModal from './documentModal/DocumentModal';
import PrintIcon from '../../../component/icon/PrintIcon';
import QuoteModal from './quoteModal/QuoteModal';
import { calcQuoteTotal, quoteTypes } from '../../../util/quotes';
import { joinDefined, prettyPrice } from '../../../util/strings';
import TickIcon from '../../../component/icon/TickIcon';
import moment from 'moment';
import { PreloadProducts } from '../../../util/products';
import HistoryField from '../../../component/form/HistoryField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getEnquiryClient } from '../../../apollo';
import gql from 'graphql-tag';
import Spinner from '../../../component/Spinner';
import { FormHelperText } from '@material-ui/core';
import GatedComponent from '../../../component/GatedComponent';
import { QuotesIcon } from '../../../component/IconIndex';

//mapping for the default billing target
const DEFAULT_BILLING_TARGET_MAPPING = {
    [BILL_TO_MAPPING.billingSource]: 'BillTo.BillingSource',
    [BILL_TO_MAPPING.billingId]: 'BillTo.BillingId',
    [BILL_TO_MAPPING.relationToDeceased]: 'BillTo.RelationToDeceased',
    [BILL_TO_MAPPING.fullName]: 'BillTo.Name',
    [BILL_TO_MAPPING.givenName]: 'BillTo.FirstName',
    [BILL_TO_MAPPING.middleName]: 'BillTo.MiddleName',
    [BILL_TO_MAPPING.surname]: 'BillTo.Surname',
    [BILL_TO_MAPPING.phone]: 'BillTo.Phone',
    [BILL_TO_MAPPING.mobile]: 'BillTo.Mobile',
    [BILL_TO_MAPPING.email]: 'BillTo.Email',
    [BILL_TO_MAPPING.line1]: 'BillTo.AddressLine1',
    [BILL_TO_MAPPING.line2]: 'BillTo.AddressLine2',
    [BILL_TO_MAPPING.city]: 'BillTo.Suburb',
    [BILL_TO_MAPPING.state]: 'BillTo.State',
    [BILL_TO_MAPPING.country]: 'BillTo.Country',
    [BILL_TO_MAPPING.code]: 'BillTo.Postcode'
};

class Billing extends Component {
    state = {
        invoiceModal: {
            open: false,
            invoiceIndex: null,
            isPurchase: null
        },
        documentModal: {
            open: false,
            document: null
        },
        quoteModal: {
            open: false,
            quote: null
        },
        loaded: false,
        callDateTime: null,
        callMadeDate: null,
        callMadeTime: null
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { form } = nextProps;
        if (form && prevState.loaded === false) {
            const callDateTime = form.getField('FollowUpCallMadeDatetime') || null;
            return {
                callDateTime,
                callMadeDate: !callDateTime || moment(callDateTime).format('YYYY-MM-DD'),
                callMadeTime: !callDateTime || moment(callDateTime).format('HH:mm'),
                loaded: true
            };
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        const { form } = prevProps;

        if (!form) return;

        const products = (form.getField('Disposal.DisposalBookingItems') || []).map(({ ProductID }) => ({ ProductID }));

        PreloadProducts(this, products);
    }

    onCallMadeDateTimeChange = event => {
        const { form } = this.props;

        if (!event.target.value) {
            // reset to NOW if empty
            const date = moment().format('YYYY-MM-DD');
            const time = moment().format('HH:mm');

            this.setState({
                callMadeDate: date,
                callMadeTime: time
            });

            form.setField({ FollowUpCallMadeDatetime: date + 'T' + time });
        } else {
            this.setState({ [event.target.name]: event.target.value }, () => {
                form.setField({ FollowUpCallMadeDatetime: this.state.callMadeDate + 'T' + this.state.callMadeTime });
            });
        }
    };

    onTickCallMade = event => {
        const { form } = this.props;
        const { callDateTime } = this.state;

        if (event.target.checked) {
            const date = moment(callDateTime || new Date()).format('YYYY-MM-DD');
            const time = moment(callDateTime || new Date()).format('HH:mm');

            this.setState({
                callMadeDate: date,
                callMadeTime: time
            });

            form.setField({ FollowUpCallMadeDatetime: date + 'T' + time });
        } else {
            form.setField({ FollowUpCallMadeDatetime: callDateTime });
        }
    };

    getQuotesList = (enquiry, pre = null) => {
        const quotes = ((enquiry && enquiry.Quotes) || []).filter(e => {
            return { ...e };
        });

        if (!!pre && !!pre.Quote) {
            quotes.push(pre.Quote);
        }
        return quotes;
    };

    render() {
        const { invoiceModal, documentModal, quoteModal } = this.state;
        const { form } = this.props;
        const enquiryID = form.getField('Enquiry.ID');
        return (
            <Fragment>
                <Query
                    client={getEnquiryClient()}
                    variables={{ ID: enquiryID }}
                    query={readOriginalEnquiry}
                    skip={!enquiryID}
                >
                    {({ data }) => {
                        const enquiry = (data && data.readOneEnquiry) || {};
                        const quotes = this.getQuotesList(enquiry);
                        return (
                            <InvoiceModal
                                onClose={() =>
                                    this.setState({
                                        invoiceModal: { open: false, invoiceIndex: null, isPurchase: null }
                                    })
                                }
                                open={!!invoiceModal.open}
                                onSubmit={e => this.onSubmitNewInvoice(e)}
                                form={form}
                                isPurchase={invoiceModal.isPurchase}
                                quotes={quotes}
                                invoiceIndex={invoiceModal.invoiceIndex}
                                defaultBillTo={form.getField('BillTo') || {}}
                            />
                        );
                    }}
                </Query>

                <DocumentModal
                    onClose={() => this.setState({ documentModal: { open: false, document: null } })}
                    open={!!documentModal.open}
                    form={form}
                    document={documentModal.document}
                />
                <QuoteModal
                    onClose={() => this.setState({ quoteModal: { open: false, quote: null } })}
                    open={!!quoteModal.open}
                    form={form}
                    quote={quoteModal.quote}
                />
                <ColumnLayout>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                </ColumnLayout>
            </Fragment>
        );
    }

    renderFirstColumn() {
        const { form, locked } = this.props;
        return (
            <Fragment>
                <Grid container spacing={16}>
                    <Grid bucket>
                        <BillTo
                            billingTargetForm={form}
                            billingSourceForm={form}
                            mapping={DEFAULT_BILLING_TARGET_MAPPING}
                            disabled={locked}
                        />
                        {this.renderAssociatedInvoices()}
                    </Grid>
                    <Grid bucket>{this.renderAssociatedQuotes()}</Grid>
                    <Grid bucket>{this.renderAssociatedBills()}</Grid>
                </Grid>
            </Fragment>
        );
    }

    renderSecondColumn() {
        const { form, classes, locked } = this.props;

        return (
            <Fragment>
                <Grid container spacing={16}>
                    <Grid bucket={true}>
                        <InlineHeader header="Job Status">
                            <Checkbox label={'This job is cancelled'} form={form} name="Cancelled" disabled={locked} />
                        </InlineHeader>
                        {form.getField('Cancelled') && (
                            <Fragment>
                                <Grid pc={0.5}>
                                    <TextField
                                        disabled={locked}
                                        type="date"
                                        label="Date Cancelled"
                                        required
                                        form={form}
                                        name="CancelledDate"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <OutlineButton
                                                        className={classes.addNewButton}
                                                        onClick={() =>
                                                            form.setField({
                                                                CancelledDate: moment().format('YYYY-MM-DD')
                                                            })
                                                        }
                                                        title="Add Today's Date"
                                                    >
                                                        Add Today's Date
                                                    </OutlineButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid pc={0.5}>
                                    <Select
                                        disabled={locked}
                                        required
                                        form={form}
                                        name="CancelledReason"
                                        label="Reason"
                                        options={[
                                            { label: 'Accidental/unwanted record', value: 'invalid' },
                                            { label: 'Went with someone cheaper', value: 'competition' }
                                        ]}
                                    />
                                </Grid>
                            </Fragment>
                        )}
                        <GatedComponent showComponentCode="porky pig">
                            {() => (
                                <>
                                    <InlineHeader header={'Funeral Key Override'} />
                                    <InlineField>
                                        <TextField
                                            disabled={locked}
                                            label="Funeral Key"
                                            form={form}
                                            name={'LegacyKey'}
                                            onChange={e => (e.target.value = ('' + e.target.value).toUpperCase())}
                                        />
                                    </InlineField>
                                </>
                            )}
                        </GatedComponent>
                    </Grid>
                    <Grid bucket={true}>
                        <InlineHeader header="Deposits">
                            <Checkbox form={form} name="DepositReceived" label={'Deposit Received'} />
                        </InlineHeader>
                        <InlineField>
                            <TextField form={form} name="DepositAmount" label={'Required Amount/Notes'} />
                        </InlineField>
                    </Grid>
                    <Grid bucket={true}>
                        <InlineHeader header="Billing Notes" />
                        <InlineField>
                            <TextField
                                placeholder="New notes are written here..."
                                multiline={true}
                                name="BillingNotes"
                                form={form}
                            />
                        </InlineField>
                        <InlineField>
                            <HistoryField value={form.getField('OldBillingNotes')} />
                        </InlineField>
                    </Grid>
                    {1 === 2 && (
                        <Grid bucket={true}>
                            <InlineHeader header="Branded Items" />
                            <InlineField>
                                <AltButton>
                                    <PrintIcon onClick={() => this.printBrandedEnvelope()} />
                                    Print Branded Envelope
                                </AltButton>
                                <AltButton>
                                    <PrintIcon onClick={() => this.printBrandedLetterhead()} />
                                    Print Branded Letterhead
                                </AltButton>
                            </InlineField>
                        </Grid>
                    )}
                    {this.renderCustomerSatisfaction()}
                </Grid>
            </Fragment>
        );
    }

    renderCustomerSatisfaction() {
        const { form, classes } = this.props;
        const hasCalledCustomer = form.getField('FollowUpCallMade') === true;
        return (
            <Fragment>
                <Grid bucket={true}>
                    <InlineHeader header="Customer Satisfaction" />
                    <Grid pc={1}>
                        <Checkbox
                            label="14 Day Follow-up Call Made"
                            name="FollowUpCallMade"
                            form={form}
                            onChange={this.onTickCallMade}
                        />
                        <br />
                        <FormHelperText>
                            Once the follow-up call is made, this record will no longer be listed on the Dashboard.
                        </FormHelperText>
                    </Grid>

                    {hasCalledCustomer && (
                        <InlineHeader header="Called When:">
                            <InlineField>
                                <TextField
                                    type="date"
                                    notFuture
                                    value={this.state.callMadeDate}
                                    name="callMadeDate"
                                    onChange={this.onCallMadeDateTimeChange}
                                />

                                <TextField
                                    type="time"
                                    notFuture
                                    value={this.state.callMadeTime}
                                    name="callMadeTime"
                                    onChange={this.onCallMadeDateTimeChange}
                                />
                            </InlineField>
                        </InlineHeader>
                    )}

                    {hasCalledCustomer && (
                        <Fragment>
                            <InlineHeader header="Performance Feedback:">
                                <InlineField>
                                    <Select
                                        label={'Overall Rating'}
                                        form={form}
                                        options={[
                                            { label: 'Zero out of ten', value: '0' },
                                            { label: 'One out of ten', value: '1' },
                                            { label: 'Two out of ten', value: '2' },
                                            { label: 'Three out of ten', value: '3' },
                                            { label: 'Four out of ten', value: '4' },
                                            { label: 'Five out of ten', value: '5' },
                                            { label: 'Six out of ten', value: '6' },
                                            { label: 'Seven out of ten', value: '7' },
                                            { label: 'Eight out of ten', value: '8' },
                                            { label: 'Nine out of ten', value: '9' },
                                            { label: 'Ten out of ten', value: '10' }
                                        ]}
                                        name="FollowUpRating"
                                        placeholder="Not set..."
                                    />

                                    <Select
                                        label={'Overall Sentiment'}
                                        options={CUSTOMER_SATISFACTION_OPTIONS}
                                        placeholder="Select a sentiment..."
                                        name="FollowUpSatisfaction"
                                        form={form}
                                    />
                                </InlineField>
                            </InlineHeader>
                        </Fragment>
                    )}

                    <Grid item xs={12}>
                        <Label text="Customer Comments" />
                        <TextField
                            className={classes.alignWithFloatingLabel}
                            form={form}
                            name="FollowUpComment"
                            placeholder="Enter any comments made by the customer..."
                            multiline={true}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderInvoiceTable(invoices, isPurchase = false) {
        const { classes } = this.props;
        let totalDue = 0;
        return invoices
            .map((obj, i) => {
                if (!!obj.IsPurchase !== !!isPurchase) return null;
                const customer = obj.Customer || {
                    FirstName: '',
                    Surname: '',
                    CustomerName: ''
                };
                let splitName = customer.FirstName || customer.Surname;
                const invTotals = calculateTotals(obj);
                if (obj.XeroStatus === 'AUTHORISED') totalDue += invTotals.amountDue;
                const overdue =
                    !!obj.InvoiceDueDate && moment(obj.InvoiceDueDate) < moment() && invTotals.amountDue > 0;
                return (
                    <TableRow key={i} pad={true}>
                        <TableCell>
                            <LinkButton
                                onClick={() =>
                                    this.setState({
                                        invoiceModal: { open: true, invoiceIndex: i, isPurchase }
                                    })
                                }
                                text={'#' + obj.ID}
                            />
                        </TableCell>
                        <TableCell>
                            {splitName
                                ? joinDefined([customer.FirstName, customer.MiddleName, customer.Surname], ' ')
                                : customer.CustomerName}
                        </TableCell>
                        <TableCell>
                            <span className={(!!overdue && classes.redText) || ''}>
                                {(!!obj.InvoiceDueDate && moment(obj.InvoiceDueDate).format('ddd D MMM YYYY')) || ''}
                            </span>
                        </TableCell>
                        {!isPurchase && (
                            <>
                                <TableCell>
                                    {obj.InvoiceStatus
                                        ? INVOICE_STATUS_OPTIONS.find(e => e.value === obj.InvoiceStatus).label
                                        : 'Normal'}
                                </TableCell>
                                <TableCell className={classes.colXs}>
                                    {(!!obj.InvoiceSent && <TickIcon className={classes.tickIcon} />) ||
                                        (obj.XeroStatus === 'AUTHORISED' && (
                                            <span className={classes.redText}>NOT SENT</span>
                                        ))}
                                </TableCell>
                            </>
                        )}
                        <TableCell>{obj.XeroID ? obj.XeroStatus : 'NOT EXPORTED'}</TableCell>
                        <TableCell className={classes.textRight}>{prettyPrice(invTotals.amountDue)}</TableCell>
                    </TableRow>
                );
            })
            .concat([
                <TableHeaderRow key={99} pad={true} className={classes.footerRowHack}>
                    <TableCell className={classes.hideCell} colSpan={!isPurchase ? 5 : 3} />
                    <TableHeaderCell>OUTSTANDING</TableHeaderCell>
                    <TableCell className={classes.textRight}>
                        <strong>{prettyPrice(totalDue)}</strong>
                    </TableCell>
                </TableHeaderRow>
            ]);
    }

    renderAssociatedInvoices() {
        const { form, locked } = this.props;
        const allInvoices = form.getField('Invoices') || [];
        const invoices = allInvoices.filter(e => !e.IsPurchase);

        return (
            <Fragment>
                <InlineHeader header="Invoices" />
                {(invoices.length > 0 && (
                    <Fragment>
                        <InlineField lineHeight={'normal'}>
                            <Grid item xs={12}>
                                <Table
                                    columns={[
                                        'Invoice ID',
                                        'Billing Name',
                                        'Due Date',
                                        'Payment Method',
                                        'Sent',
                                        'Xero Status',
                                        'Amount Due'
                                    ]}
                                >
                                    {this.renderInvoiceTable(allInvoices, 0)}
                                </Table>
                            </Grid>
                        </InlineField>
                        {/*<InlineField>
                            <AltButton>
                                <PrintIcon onClick={() => this.printAllInvoices(invoices)} /> &nbsp; Print All Invoices
                            </AltButton>
                        </InlineField>*/}
                    </Fragment>
                )) || <Grid pc={1}>There are currently no invoices for this funeral.</Grid>}
                <GatedComponent isEnabledCode={'FM_ACCESS_Quotes_Edit'}>
                    {() => (
                        <InlineField>
                            <PrimaryButton
                                disabled={locked}
                                onClick={() =>
                                    this.setState({
                                        invoiceModal: { open: true, invoiceIndex: null, isPurchase: false }
                                    })
                                }
                            >
                                <QuotesIcon />
                                Add new Invoice...
                            </PrimaryButton>
                        </InlineField>
                    )}
                </GatedComponent>
            </Fragment>
        );
    }

    renderAssociatedBills() {
        const { form } = this.props;
        const allInvoices = form.getField('Invoices') || [];

        const bills = allInvoices.filter(e => !!e.IsPurchase);
        return (
            <Fragment>
                <InlineHeader header="Associated Bills" />
                {(bills.length > 0 && (
                    <Fragment>
                        <InlineField lineHeight={'normal'}>
                            <Grid item xs={12}>
                                <Table columns={['Bill ID', 'Supplier Name', 'Due Date', 'Xero Status', 'Amount Due']}>
                                    {this.renderInvoiceTable(allInvoices, 1)}
                                </Table>
                            </Grid>
                        </InlineField>
                        {/*<InlineField>
                            <AltButton>
                                <PrintIcon onClick={() => this.printAllInvoices(bills)} /> &nbsp; Print All Bills
                            </AltButton>
                        </InlineField>*/}
                    </Fragment>
                )) || <Grid pc={1}>There are currently no associated bills.</Grid>}
                <GatedComponent isEnabledCode={'FM_ACCESS_Quotes_Edit'}>
                    {() => (
                        <InlineField>
                            <PrimaryButton
                                onClick={() =>
                                    this.setState({
                                        invoiceModal: { open: true, invoiceIndex: null, isPurchase: true }
                                    })
                                }
                            >
                                <QuotesIcon />
                                Add new bill...
                            </PrimaryButton>
                        </InlineField>
                    )}
                </GatedComponent>
            </Fragment>
        );
    }

    renderAssociatedQuotes() {
        const { form } = this.props;
        const enquiryID = form.getField('Enquiry.ID');
        return (
            <Query
                client={getEnquiryClient()}
                variables={{ ID: enquiryID }}
                query={readOriginalEnquiry}
                skip={!enquiryID}
            >
                {results => this.renderAssociatedQuotesFound(results)}
            </Query>
        );
    }

    renderAssociatedQuotesFound = ({ data, loading, error }) => {
        const { classes } = this.props;
        const enquiry = (data && data.readOneEnquiry) || {};
        const pre =
            !!enquiry &&
            ((!!enquiry.Prearranged && Number(enquiry.Prearranged.ID) > 0 && enquiry.Prearranged) ||
                (!!enquiry.Prearrangement && Number(enquiry.Prearrangement.ID) > 0 && enquiry.Prearrangement));
        const allQuotes = this.getQuotesList(enquiry, pre) || [];
        const quotes = allQuotes.filter(e => !!e.Accepted);
        return (
            <Fragment>
                <InlineHeader header="Associated Quotes" />
                {(quotes.length > 0 && (
                    <Fragment>
                        <InlineField lineHeight={'normal'}>
                            <Grid item xs={12}>
                                <Table
                                    columns={[
                                        'Quote ID',
                                        'Enquirer Name',
                                        'Type',
                                        'Date Responded',
                                        'Amount',
                                        'Accepted'
                                    ]}
                                >
                                    {quotes.map((obj, i) => {
                                        const total = calcQuoteTotal(obj.QuoteItems.edges.map(e => e.node));
                                        const quoteType = Object.keys(quoteTypes).find(
                                            key => quoteTypes[key] === obj.QuoteType
                                        );
                                        return (
                                            <TableRow key={i} pad={true}>
                                                <TableCell>
                                                    <LinkButton
                                                        onClick={() =>
                                                            this.setState({
                                                                quoteModal: { open: true, quote: obj }
                                                            })
                                                        }
                                                        text={obj.ID}
                                                    >
                                                        #
                                                    </LinkButton>
                                                </TableCell>
                                                <TableCell>
                                                    {joinDefined(
                                                        [enquiry.EnquirerGivenName, enquiry.EnquirerSurname],
                                                        ' '
                                                    )}
                                                </TableCell>
                                                <TableCell>{quoteType}</TableCell>
                                                <TableCell>
                                                    {!!obj.RespondedDate &&
                                                        moment(obj.RespondedDate).format('D/MM/YYYY h:mma')}
                                                </TableCell>
                                                <TableCell className={classes.textRight}>
                                                    {prettyPrice(total)}
                                                </TableCell>
                                                <TableCell className={classes.colXs}>
                                                    {!!obj.Accepted && <TickIcon className={classes.tickIcon} />}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </Table>
                            </Grid>
                        </InlineField>
                    </Fragment>
                )) || (
                    <Grid pc={1}>
                        {!!loading ? (
                            <span>
                                <Spinner /> Loading...
                            </span>
                        ) : (
                            <span>There are no associated quotes.</span>
                        )}
                    </Grid>
                )}
                <InlineField>
                    {enquiry && Number(enquiry.ID) > 0 && (
                        <Fragment>
                            <OutlineButton target="_blank" onClick={() => this.viewInitialEnquiry(enquiry)}>
                                View Enquiry {enquiry.LegacyKey}
                            </OutlineButton>
                            {pre && Number(pre.ID) > 0 && (
                                <OutlineButton target="_blank" onClick={() => this.viewInitialPreneed(pre)}>
                                    View Prearrangement {pre.LegacyKey}
                                </OutlineButton>
                            )}
                        </Fragment>
                    )}
                    {quotes && quotes.length > 999 && (
                        <AltButton>
                            <PrintIcon onClick={() => this.printAllQuotes()} />
                            Print All Quotes
                        </AltButton>
                    )}
                </InlineField>
            </Fragment>
        );
    };

    printBrandedLetterhead() {
        //todo:
    }

    printBrandedEnvelope() {
        //todo:
    }

    printAllInvoices(invoiceList) {
        //todo:
    }

    printAllQuotes() {
        //todo:
    }

    viewInitialEnquiry(obj) {
        const enqID = obj.ID;
        const enqKey = obj.LegacyKey;
        const path = `/quotes/enquiry/${enqKey}/${enqID}`;
        const win = window.open(path, '_blank');
        win.focus();
    }

    viewInitialPreneed(obj) {
        const enqID = obj.ID;
        const enqKey = obj.LegacyKey;
        const path = `/prearrangement/${enqKey}/${enqID}`;
        const win = window.open(path, '_blank');
        win.focus();
    }

    onSubmitNewInvoice(e) {
        this.setState({ invoiceModal: { open: false, invoiceIndex: null, isPurchase: null } });
    }
}

const readOriginalEnquiry = gql`
    query ReadOriginalEnquiry($ID: ID!) {
        readOneEnquiry(ID: $ID) {
            ID
            LegacyKey
            EnquirerGivenName
            EnquirerSurname
            Prearrangement {
                ID
                LegacyKey
                Quote {
                    ID
                    Created
                    Title
                    QuoteType
                    Accepted
                    RespondedDate
                    CalcQuoteTotal
                    QuoteItems {
                        edges {
                            node {
                                ID
                                Qty
                                Complimentary
                                Optional
                                Prepaid
                                Price
                                Title
                                TotalIncGST
                                Product {
                                    ID
                                    Title
                                    GST
                                    InternalItemID
                                    BasePrice
                                }
                                Variation {
                                    ID
                                    ShortDescription
                                    GST
                                    InternalItemID
                                    Price
                                }
                            }
                        }
                    }
                }
            }
            Prearranged {
                ID
                LegacyKey
                Quote {
                    ID
                    Created
                    Title
                    QuoteType
                    Accepted
                    RespondedDate
                    CalcQuoteTotal
                    QuoteItems {
                        edges {
                            node {
                                ID
                                Qty
                                Complimentary
                                Optional
                                Prepaid
                                Price
                                Title
                                TotalIncGST
                                Product {
                                    ID
                                    Title
                                    GST
                                    InternalItemID
                                    BasePrice
                                }
                                Variation {
                                    ID
                                    ShortDescription
                                    GST
                                    InternalItemID
                                    Price
                                }
                            }
                        }
                    }
                }
            }
            Quotes {
                ID
                Created
                Title
                QuoteType
                Released
                Accepted
                RespondedDate
                AbsolutePDFLink
                PDFLastEdited
                CalcQuoteTotal
                QuoteItems {
                    edges {
                        node {
                            ID
                            Qty
                            Complimentary
                            Optional
                            Prepaid
                            Price
                            Title
                            TotalIncGST
                            Product {
                                ID
                                Title
                                GST
                                InternalItemID
                                BasePrice
                            }
                            Variation {
                                ID
                                ShortDescription
                                GST
                                InternalItemID
                                Price
                            }
                        }
                    }
                }
            }
        }
    }
`;

const styles = ({ palette, funeralHome }) => ({
    colXs: {
        width: '60px',
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    redText: {
        color: 'red'
    },
    hideCell: {
        borderColor: 'transparent !important',
        backgroundColor: 'transparent !important'
    },
    footerRowHack: {
        '& > *': {
            borderTop: '2px solid ' + palette.contentForeground[funeralHome] + ' !important'
        },
        '& td': {
            backgroundColor: 'white',
            borderRightWidth: '1px !important'
        }
    },
    addNewButton: {
        display: 'inline-block',
        margin: '0 -4px 0 0',
        borderRadius: '20px 3px 3px 20px',
        height: 28,
        minWidth: 'unset',
        whiteSpace: 'pre',
        padding: '0 6px',
        textAlign: 'center',
        '& > span': {
            fontSize: '0.75em'
        }
    },
    tickIcon: { color: palette.button.save }
});

// prettier-ignore
export default compose(
    withRouter,
    withStyles(styles)
)(Billing);
