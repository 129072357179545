import gql from 'graphql-tag';
import { getClient } from '../../../apollo';
import { joinDefined } from '../../../util/strings';
import { isNullOrUndefined } from '../../../util/objects';
import { isRelatedObjectUndefined } from '../../../util/graphql';
import { getVariationDescription } from '../../../util/products';

export const CoffinListFragment = gql`
    fragment CoffinList on Funeral {
        ID
        LegacyKey
        FirstName
        MiddleName
        Surname
        Arrangers {
            ID
            Member {
                ID
                FirstName
                Surname
            }
        }

        Disposal {
            ID
            CrematedOrBuried
            DisposalBookingItems {
                ID
                Quantity
                Variation {
                    ...Variation
                }
                Product {
                    ID
                    Title
                    LegacyKey
                    InternalItemID
                    ShortDescription
                    ImageURL
                    ImagePortraitURL
                    ImageLandscapeURL

                    Parent {
                        ... on ProductCategory {
                            ID
                            URLSegment
                        }
                        ... on CoreProductCategory {
                            ID
                            URLSegment
                        }
                    }
                    ProductCategories {
                        ... on ProductCategory {
                            ID
                            URLSegment
                            Title
                        }
                        ... on CoreProductCategory {
                            ID
                            URLSegment
                            Title
                        }
                    }
                }
                Comment
            }
        }
    }

    fragment Variation on Variation {
        ID
        LegacyKey
        InternalItemID
        ImageURL
        ImagePortraitURL
        ImageLandscapeURL

        Price
        AttributeValues {
            edges {
                node {
                    ID
                    Value
                    Type {
                        ID
                        Label
                    }
                }
            }
        }
    }
`;

const CoffinListQuery = gql`
    ${CoffinListFragment}

    query CoffinList($offset: Int) {
        readFunerals(mortuary: true, limit: 10, offset: $offset) {
            edges {
                node {
                    ...CoffinList
                }
            }
            pageInfo {
                hasNextPage
                totalCount
            }
        }
    }
`;

export const coffinProductList = gql`
    query {
        readProducts(categoryUrlsegment: "coffins") {
            edges {
                node {
                    ID
                    ImageURL
                    InternalItemID
                    Title
                    ImageLandscapeURL
                    Variations {
                        edges {
                            node {
                                ID
                                InternalItemID
                                ImageLandscapeURL
                                AttributeValues {
                                    edges {
                                        node {
                                            ID
                                            Value
                                            Type {
                                                ID
                                                Label
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getCoffinList = (offset = 0) => {
    const variables = { offset };
    return getClient()
        .query({ query: CoffinListQuery, fetchPolicy: 'network-only', variables })
        .then(
            ({ data }) => {
                const { readFunerals } = data;
                const { hasNextPage, totalCount } = readFunerals.pageInfo || false;
                try {
                    return {
                        totalCount,
                        hasNextPage,
                        items: readFunerals.edges.map(({ node }) => createCoffinRow(node))
                    };
                } catch (ex) {
                    console.error('failed to get coffin list', ex);
                    return { error: 'readFunerals was not returned' + ex };
                }
            },
            e => {
                console.error('failed to get coffin list', e);
                return { error: 'failed to get coffin list' };
            }
        );
};

const createCoffinRow = funeral => {
    let row = {
        ID: funeral.ID,
        LegacyKey: funeral.LegacyKey,
        Deceased: joinDefined([(funeral.Surname || '').toUpperCase(), funeral.FirstName, funeral.MiddleName], ' '),
        Arrangers: funeral.Arrangers,
        Disposal: funeral.Disposal.CrematedOrBuried,
        SelectedCoffinCode: '',
        SelectedCoffinDescription: '',
        SelectedCoffinImage: '',
        ActualCoffinCode: '',
        ActualCoffinDescription: '',
        ActualCoffinImage: ''
    };

    const selectedCoffin = getCoffin(funeral, true);
    if (selectedCoffin) {
        row.SelectedCoffinCode = getCoffinCode(selectedCoffin);
        row.SelectedCoffinDescription = getCoffinDescription(selectedCoffin);
        row.SelectedCoffinComment = selectedCoffin.Comment;
        row.SelectedCoffinImage = getCoffinImage(selectedCoffin);
    }

    const actualCoffin = getCoffin(funeral, false);
    if (actualCoffin) {
        row.ActualCoffinCode = getCoffinCode(actualCoffin);
        row.ActualCoffinDescription = getCoffinDescription(actualCoffin);
        row.ActualCoffinComment = actualCoffin.Comment;
        row.ActualCoffinImage = getCoffinImage(actualCoffin);
    }

    return row;
};

export const getCoffin = (funeral, selected) => {
    if (!funeral) return null;
    const coffinIndex = getCoffinIndex(funeral, selected);
    return coffinIndex >= 0 ? funeral.Disposal.DisposalBookingItems[coffinIndex] : null;
};

export const getCoffinIndex = (funeral, selected) => {
    if (!funeral) return -1;
    if (!funeral.Disposal || !funeral.Disposal.DisposalBookingItems) return -1;

    const collection = funeral.Disposal.DisposalBookingItems.edges || funeral.Disposal.DisposalBookingItems || [];

    let coffins = [];

    //get the coffins and their indices
    collection.forEach((item, index) => {
        const bookingItem = item.node || item;
        if (isCategory(bookingItem, 'coffins')) coffins.push({ index, bookingItem });
    });

    //if there's only 1, it has to be the one.
    if (coffins.length === 1) {
        return selected ? coffins[0].index : -1;
    }

    //ok we need to sort through and find the quantity 0 = selected, 1 = actual
    for (let x = 0; x < coffins.length; x++) {
        if (
            (selected && coffins[x].bookingItem.Quantity === 0) ||
            (!selected && coffins[x].bookingItem.Quantity === 1)
        ) {
            return coffins[x].index;
        }
    }
    return -1;
};

export const getCategoryIndices = (funeral, category) => {
    if (!funeral.Disposal || !funeral.Disposal.DisposalBookingItems) return [];
    const collection = funeral.Disposal.DisposalBookingItems.edges || funeral.Disposal.DisposalBookingItems || [];
    return collection
        .map((item, index) => {
            const bookingItem = item.node || item;
            return { index, bookingItem };
        })
        .filter(kvp => isCategory(kvp.bookingItem, category));
};

export const isCategory = (bookingItem, category) => {
    if (!isNullOrUndefined(bookingItem.ProductID)) return bookingItem.URLSegment === category;
    const categories = bookingItem.Product.ProductCategories.map(x => x.URLSegment).concat([
        !!bookingItem.Product.Parent && bookingItem.Product.Parent.URLSegment
    ]);
    return categories.filter(x => x === category).length > 0;
};

export const getCoffinDescription = coffin => {
    if (isNullOrUndefined(coffin)) return '';
    const { Title } = coffin.Product;
    return isRelatedObjectUndefined(coffin.Variation)
        ? Title
        : getVariationDescription(Title, coffin.Variation.AttributeValues);
};

export const getCoffinCode = coffin => {
    if (isNullOrUndefined(coffin)) return null;
    if (isRelatedObjectUndefined(coffin.Variation)) {
        return coffin.Product.InternalItemID;
    }
    return coffin.Variation.InternalItemID;
};

export const getCoffinImage = coffin => {
    if (isNullOrUndefined(coffin)) return null;
    if (isRelatedObjectUndefined(coffin.Variation)) {
        return coffin.Product.ImageLandscapeURL;
    }
    return coffin.Variation.ImageLandscapeURL;
};
