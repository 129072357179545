import Deceased from './Deceased';
import DeceasedFragment from './DeceasedFragment';
import { ABORIGINAL_OPTIONS, DATE_OF_DEATH_OPTION, TORRES_OPTIONS } from './DeceasedConstants';
import { isContactDefined } from '../../../util/bookable';
import { joinDefined, titleCase } from '../../../util/strings';
import { joinValidationResults, validationHelper } from '../../../util/validation';
import { isNullOrUndefined } from '../../../util/objects';
import { isDefinedAndDifferent } from '../../../util/functions';

export default {
    label: 'Deceased',
    component: Deceased,
    fragment: DeceasedFragment,
    onLoad: data => {
        if (!!data.DateEnteredAustralia) {
            data.DateEnteredAustralia = data.DateEnteredAustralia.substring(0, 4);
        }
        if (!!data.DeceasedChangesFromBDM) {
            data.DeceasedChangesFromBDM = JSON.parse(data.DeceasedChangesFromBDM || '{}');
            if (!!data.DeceasedChangesFromBDM.DateEnteredAustralia) {
                data.DeceasedChangesFromBDM.DateEnteredAustralia = data.DeceasedChangesFromBDM.DateEnteredAustralia.substring(
                    0,
                    4
                );
            }
        }
    },
    formatSaveData: (saveData, state) => {
        if (!state.Aboriginal) saveData.Aboriginal = 'N';
        if (!state.TorresStrait) saveData.TorresStrait = 'N';
        if (!isNullOrUndefined(saveData.UnknownDOB)) {
            saveData.UnknownDOB = !!saveData.UnknownDOB;
        }

        if (state.PlaceOfBirthCountry === 'Australia') {
            saveData.DateEnteredAustralia = null;
        } else if (
            !state.PlaceOfBirth1 ||
            (state.PlaceOfBirth1.toLowerCase() === 'unknown' && !state.PlaceOfBirthCountry)
        ) {
            saveData.PlaceOfBirth1 = 'Unknown';
            saveData.PlaceOfBirthState = null;
            saveData.PlaceOfBirthCountry = null;
        }
        if (!isNullOrUndefined(saveData.DateEnteredAustralia)) {
            saveData.DateEnteredAustralia = `${saveData.DateEnteredAustralia}-01-01`;
        } else {
            delete saveData.DateEnteredAustralia;
        }

        if (state.DateOfDeath1 && state.DateOfDeathType === DATE_OF_DEATH_OPTION.UNKNOWN.value) {
            saveData.DateOfDeath1 = null;
        }

        if (state.Pensioner !== 'Y' && !!state.PensionType) {
            saveData.PensionType = null;
        }

        if (isContactDefined(saveData.PlaceOfDeath)) {
            saveData.PlaceOfDeathID = saveData.PlaceOfDeath.ID;
            delete saveData.PlaceOfDeath;
        }

        if (isContactDefined(saveData.TransferFrom)) {
            saveData.TransferFromID = saveData.TransferFrom.ID;
            delete saveData.TransferFrom;
        }

        if (saveData.Occupation) saveData.Occupation = titleCase(saveData.Occupation);
        if (saveData.EstimatedDeceasedWeight)
            saveData.EstimatedDeceasedWeight = parseFloat(saveData.EstimatedDeceasedWeight);

        // recalculate age if no longer using manual age
        if (saveData.ManualAge !== undefined && saveData.ManualAge === false) {
            saveData.Age = null;
            saveData.AgeCode = null;
        }
        if (saveData.DeceasedPhotos) {
            saveData.DeceasedPhotos = saveData.DeceasedPhotos.map(({ ID }) => ({ ID }));
        }
        if (saveData.MemorialPhoto) {
            saveData.MemorialPhotoID = saveData.MemorialPhoto.ID || '0';
            delete saveData.MemorialPhoto;
        }
        if (saveData.ReferencePhoto) {
            saveData.ReferencePhotoID = saveData.ReferencePhoto.ID || '0';
            delete saveData.ReferencePhoto;
        }
    },
    validation: {
        required: ['Gender'],
        suggested: ['DateOfDeathType'],
        onValidate: {
            FirstName: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'FirstName';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.required();
            },
            MiddleName: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'MiddleName';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            Surname: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'Surname';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.required();
            },
            DeceasedPhotos: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const validationResults = []; // empty means OK.
                if (isDefinedAndDifferent(changes['MemorialPhoto.ID'], getField('MemorialPhoto.ID'))) {
                    validationResults.push(
                        validationHelper.optional(
                            'Family selected for Memorial Book photo: ' +
                                (!changes['MemorialPhoto.ID'] ? '(empty)' : changes['MemorialPhoto.ID'])
                        )
                    );
                }
                const photoList = getField('DeceasedPhotos');
                if (!Array.isArray(photoList) || !photoList.length) {
                    validationResults.push(
                        validationHelper.suggested(
                            'Please upload photos of the deceased and select' +
                                ' a Mortuary Reference photo and a Memorial Book photo.'
                        )
                    );
                } else if (!getField('MemorialPhoto.ID') || !getField('ReferencePhoto.ID')) {
                    validationResults.push(
                        validationHelper.suggested(
                            'Please select ' +
                                joinDefined(
                                    [
                                        !getField('ReferencePhoto.ID') && 'a Mortuary Reference photo',
                                        !getField('MemorialPhoto.ID') && 'a Memorial Book photo'
                                    ],
                                    ' and '
                                ) +
                                '.'
                        )
                    );
                }
                return joinValidationResults(validationResults);
            },
            SurnameAtBirth: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'FamilyNameAtBirth';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            PlaceOfDeathType: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'PlaceOfDeathType';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            UseResidentialAddress: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'UseResidentialAddress';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (!changes[fieldName] ? 'No' : 'Yes'));
                }
                return validationHelper.ok();
            },
            'PlaceOfDeathResidentialAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'PlaceOfDeathResidentialAddress';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    const street = 'PlaceOfDeathResidentialAddress.AddressLine1';
                    const city = 'PlaceOfDeathResidentialAddress.Suburb';
                    const state = 'PlaceOfDeathResidentialAddress.State';
                    const postcode = 'PlaceOfDeathResidentialAddress.Postcode';
                    const country = 'PlaceOfDeathResidentialAddress.Country';
                    if (
                        ![street, city, state, postcode, country].every(
                            fieldName => !isDefinedAndDifferent(changes[fieldName], getField(fieldName))
                        )
                    ) {
                        return validationHelper.optional(
                            'Family entered: ' +
                                (joinDefined(
                                    [
                                        changes[street] || '',
                                        joinDefined(
                                            [changes[city] || '', changes[state] || '', changes[postcode] || ''],
                                            ' '
                                        ),
                                        changes[country] || ''
                                    ],
                                    ', '
                                ) || '(empty)')
                        );
                    }
                }
                return validationHelper.ok();
            },
            'PlaceOfDeath.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'PlaceOfDeath.ID';
                if (isDefinedAndDifferent(changes['PlaceOfDeathID'], getField(fieldName))) {
                    return validationHelper.optional('Family selected this hospital');
                }
                return validationHelper.ok();
            },
            ResidentialAddress1: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const street = 'ResidentialAddress1';
                const city = 'ResidentialTown';
                const state = 'ResidentialState';
                const country = 'ResidentialCountry';
                const postcode = 'PostCode';
                if (
                    ![street, city, state, postcode, country].every(
                        fieldName => !isDefinedAndDifferent(changes[fieldName], getField(fieldName))
                    )
                ) {
                    return validationHelper.optional(
                        'Family entered: ' +
                            (joinDefined(
                                [
                                    changes[street] || '',
                                    joinDefined(
                                        [changes[city] || '', changes[state] || '', changes[postcode] || ''],
                                        ' '
                                    ),
                                    changes[country] || ''
                                ],
                                ', '
                            ) || '(empty)')
                    );
                }
                return validationHelper.ok();
            },
            DateOfDeath1: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'DateOfDeath1';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                const dateOfDeathType = getField('DateOfDeathType');
                if (hasValue || dateOfDeathType === DATE_OF_DEATH_OPTION.UNKNOWN.value) return validationHelper.ok();

                return validationHelper.suggested();
            },
            DateOfDeath2: (newValue, persistedValue, hasValue, getField) => {
                const dateOfDeathType = getField('DateOfDeathType');
                if (hasValue || dateOfDeathType !== DATE_OF_DEATH_OPTION.BETWEEN.value) return validationHelper.ok();

                return validationHelper.required();
            },
            DateOfBirth: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'DateOfBirth';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional(
                        'Family entered: ' + (changes[fieldName] ? changes[fieldName] : '(empty)')
                    );
                }
                if (getField('DateOfDeath1') < persistedValue)
                    return validationHelper.required('Must not be after date of death.');
                if (getField('UnknownDOB') === true || !isNullOrUndefined(persistedValue)) return validationHelper.ok();

                return validationHelper.required();
            },
            UnknownDOB: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'UnknownDOB';
                if (!!changes['DateOfBirth'] && !!changes['DateOfBirth'] !== !getField(fieldName)) {
                    return validationHelper.optional(
                        'Family entered: ' + (!!changes['DateOfBirth'] ? changes['DateOfBirth'] : 'Date Unknown')
                    );
                }
                return validationHelper.ok();
            },
            Age: (newValue, persistedValue, hasValue, getField) => {
                if (isNullOrUndefined(persistedValue) || Number(persistedValue) < 1) {
                    if (
                        getField('DateOfDeathType') === 'Unknown' ||
                        getField('UnknownDOB') === true ||
                        getField('ManualAge')
                    ) {
                        if (['Stillborn', 'Not Stated', 'Unknown'].indexOf(getField('AgeCode')) < 0)
                            return validationHelper.required();
                    }
                }
                return validationHelper.ok();
            },
            AgeCode: (newValue, persistedValue, hasValue, getField) => {
                if (
                    getField('DateOfDeathType') === 'Unknown' ||
                    getField('UnknownDOB') === true ||
                    getField('ManualAge')
                ) {
                    if (isNullOrUndefined(persistedValue)) return validationHelper.required();
                }
                return validationHelper.ok();
            },
            PlaceOfBirth1: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'PlaceOfBirth1';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            PlaceOfBirthState: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'PlaceOfBirthState';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            PlaceOfBirthCountry: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'PlaceOfBirthCountry';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            DateEnteredAustralia: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'DateEnteredAustralia';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                const placeOfBirthCountry = getField('PlaceOfBirthCountry');
                const placeOfBirth1 = getField('PlaceOfBirth1');
                const dateEnteredUnknown = getField('DateEnteredAustraliaUnknown');

                if (placeOfBirthCountry === 'Australia' || placeOfBirth1 === 'Unknown' || dateEnteredUnknown) {
                    return validationHelper.ok();
                }

                if (!isNullOrUndefined(persistedValue)) {
                    const value = parseInt(persistedValue, 10);
                    const max = new Date().getFullYear();
                    const min = max - 120;
                    return value < min || value > max
                        ? validationHelper.required(`Must be between ${min} and ${max}`)
                        : validationHelper.ok();
                }

                return validationHelper.suggested();
            },
            Occupation: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'Occupation';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.suggested();
            },
            Pensioner: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'Pensioner';
                const typeFieldName = 'PensionType';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional(
                        'Family entered: ' +
                            (changes[fieldName] === 'Y' ? 'Yes' : 'No') +
                            (changes[typeFieldName] !== undefined ? ' (' + changes[typeFieldName] + ')' : '')
                    );
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.suggested();
            },
            PensionType: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'PensionType';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                if (hasValue || 'Y' !== getField('Pensioner')) return validationHelper.ok();
                return validationHelper.suggested();
            },
            Retired: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'Retired';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] === 'Y' ? 'Yes' : 'No'));
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.suggested();
            },
            Aboriginal: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'Aboriginal';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional(
                        'Family entered: ' +
                            (ABORIGINAL_OPTIONS.find(opt => opt.value === changes[fieldName]) || { label: '(empty)' })
                                .label
                    );
                }
                return validationHelper.ok();
            },
            TorresStrait: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('DeceasedChangesFromBDM') || {};
                const fieldName = 'TorresStrait';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional(
                        'Family entered: ' +
                            (TORRES_OPTIONS.find(opt => opt.value === changes[fieldName]) || { label: '(empty)' }).label
                    );
                }
                return validationHelper.ok();
            }
        }
    }
};
